import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/Layout"
import Header from "../components/Header/Header"
import Footer from "../components/Footer"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import PageTitle from "../components/PageTitle"

const StyledContainer = styled(Container)`
  margin-bottom: 30px;
  padding-left: 0px !important;
  padding-right: 0px !important;
`
const StyledRow = styled(Row)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const StyledH3 = styled.h3`
  margin-top: 5px;
  margin-bottom: 0px;
`

const StyledH4 = styled.h4`
  color: #cf3400;
  margin-bottom: 25px;
`

const StyledP = styled.p`
  font-size: 1.05em;
`

export class Team extends Component {
  render() {
    return (
      <div>
        <Layout />
        <Header />
          <StyledContainer fluid>
            <PageTitle link="/team" title="M8 Team" />
            <div className="container">
              <StyledRow>
                <Col xs={12} sm={6} lg={3}>
                  <Img
                    fixed={this.props.data.max_safai.childImageSharp.fixed}
                    alt="Max Safai"
                  />
                  <StyledH3>Max Safai</StyledH3>
                  <StyledH4>CEO / Founder</StyledH4>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Img
                    fixed={this.props.data.richard_foley.childImageSharp.fixed}
                    alt="Richard Foley"
                  />
                  <StyledH3>Richard Foley</StyledH3>
                  <StyledH4>VP Sales</StyledH4>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Img
                    fixed={this.props.data.doug_denardo.childImageSharp.fixed}
                    alt="Doug Denardo"
                  />
                  <StyledH3>Doug Denardo</StyledH3>
                  <StyledH4>Finance Director</StyledH4>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Img
                    fixed={this.props.data.lawson_fisher.childImageSharp.fixed}
                    alt="Lawson Fisher"
                  />
                  <StyledH3>Lawson Fisher</StyledH3>
                  <StyledH4>Hardware Engineering Manager</StyledH4>
                </Col>
              </StyledRow>
              <StyledRow>
                <Col xs={12} sm={6} lg={3}>
                  <Img
                    fixed={this.props.data.rainer_richter.childImageSharp.fixed}
                    alt="Rainer Richter"
                  />
                  <StyledH3>Rainer Richter</StyledH3>
                  <StyledH4>Systems Engineering Manager</StyledH4>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Img
                    fixed={this.props.data.dale_jones.childImageSharp.fixed}
                    alt="Dale Jones"
                  />
                  <StyledH3>Dale Jones</StyledH3>
                  <StyledH4>Product Manager </StyledH4>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Img
                    fixed={this.props.data.rohit_joshi.childImageSharp.fixed}
                    alt="Rohit Joshi"
                  />
                  <StyledH3>Rohit Joshi</StyledH3>
                  <StyledH4>Senior Software Engineer</StyledH4>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Img
                    fixed={this.props.data.kristi_richter.childImageSharp.fixed}
                    alt="Kristi Richter"
                  />
                  <StyledH3>Kristi Richter</StyledH3>
                  <StyledH4>Software Engineer</StyledH4>
                </Col>
              </StyledRow>
              <StyledRow>
                <Col xs={12} sm={6} lg={3}>
                  <Img
                    fixed={this.props.data.isabelle_safai.childImageSharp.fixed}
                    alt="Isabelle Barteaux"
                  />
                  <StyledH3>Isabelle Barteaux</StyledH3>
                  <StyledH4>Strategic Partnership Manager, Software Engineer</StyledH4>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Img
                    fixed={this.props.data.ethan_safai.childImageSharp.fixed}
                    alt="Ethan Safai"
                  />
                  <StyledH3>Ethan Safai</StyledH3>
                  <StyledH4>Software Engineer</StyledH4>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Img
                    fixed={this.props.data.tate_hilmoe.childImageSharp.fixed}
                    alt="Tate Hilmoe"
                  />
                  <StyledH3>Tate Hilmoe</StyledH3>
                  <StyledH4>General Counsel</StyledH4>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Img
                    fixed={this.props.data.clay_bendigkeit.childImageSharp.fixed}
                    alt="Clay Bendigkeit"
                  />
                  <StyledH3>Clay Bendigkiet</StyledH3>
                  <StyledH4>Account Executive</StyledH4>
                </Col>
              </StyledRow>
              <StyledRow>
                <Col xs={12} sm={6} lg={3}>
                  <Img
                    fixed={this.props.data.tania_tuluie.childImageSharp.fixed}
                    alt="Tania Tuluie"
                  />
                  <StyledH3>Tania Tuluie</StyledH3>
                  <StyledH4>Creative Director</StyledH4>
                </Col>
              </StyledRow>
            </div>
          </StyledContainer>
        <Footer />
      </div>
    )
  }
}
export default props => (
  <StaticQuery
    query={graphql`
      query {
        max_safai: file(relativePath: { eq: "team/max-safai.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        dale_jones: file(relativePath: { eq: "team/dale-jones.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        doug_denardo: file(relativePath: { eq: "team/doug-denardo.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        tania_tuluie: file(relativePath: { eq: "team/tania-tuluie.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        isabelle_safai: file(relativePath: { eq: "team/isabelle-safai.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        lawson_fisher: file(relativePath: { eq: "team/lawson-fisher.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        rainer_richter: file(relativePath: { eq: "team/rainer-richter.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        richard_foley: file(relativePath: { eq: "team/richard-foley.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        kristi_richter: file(relativePath: { eq: "team/kristi-richter.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        clay_bendigkeit: file(relativePath: { eq: "team/clayton-bendigkeit.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        ethan_safai: file(relativePath: { eq: "team/ethan-safai.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        steve_kauffman: file(relativePath: { eq: "team/steven-kauffman.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        tate_hilmoe: file(relativePath: { eq: "team/tate-hilmoe.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        rohit_joshi: file(relativePath: { eq: "team/rohit-joshi.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => <Team data={data} {...props} />}
  />
)
